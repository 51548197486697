<template>
    <div class="login-container">
      <div class="login-wrap">
        <div class="login-input-wrap">
          <Logo class="logo"></Logo>
          <p class="title">
            {{ $env.VUE_APP_TITLE }}
          </p>
          <el-form ref="form" :model="form" :rules="formRule" label-width="0px">
            <el-form-item prop="username">
              <el-input clearable ref="loginName" class="input-noBorder-container" prefix-icon="el-icon-user" v-model="form.username" :placeholder="$t('ple_ent_username')"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input @keyup.enter.native="loginSubmit" clearable  class="input-noBorder-container" prefix-icon="el-icon-lock" v-model="form.password" type="password" :placeholder="$t('ple_ent_password')"></el-input>
            </el-form-item>
            <el-form-item prop="remember">
              <el-checkbox v-model="form.remember">
                {{$t('remember_password')}}
              </el-checkbox>
            </el-form-item>
            <el-button class="login-button" @click="loginSubmit">登录</el-button>
          </el-form>
        </div>
      </div>
      <!-- 滑块验证码组件 -->
      <drag ref="drag" @close="closeDrag"></drag>
    </div>
</template>
<script>
import CryptoJS from 'crypto-js'
import { mapState, mapActions } from 'vuex'
import drag from '@/components/drag.vue'
import Logo from '@/components/logo.vue'
import UserLoginClass from './userLoginClass'
import apis from '@/apis'
import Cookie from '@/assets/js/cookie'

export default {
	components: {
      Logo,
      drag,
	},
  data() {
    return {
      form: new UserLoginClass('form'),
      formRule: new UserLoginClass('rule'),
      USER: new Cookie('userName'), // 实例化  账号cookie
      PASSWORD: new Cookie('password'), // 密码 cookie
      ISCHECKED: new Cookie('check'), // 记住密码是否勾选 cookie
    }
  },
  computed: {
    ...mapState(['timestampDiff', 'Config', 'SystemName']),
  },
  methods: {
    ...mapActions({
      Login: 'Login',
      setRouter: 'setRouter',
      getSystemName: 'getSystemName',
      getSystemIcon: 'getSystemIcon',
    }),
    /**
     * @function 初始化登录页面，取出记住的密码等
     */
    initLogin() {
      if (this.ISCHECKED.getCookie() === '1') {
        // 判断记住密码是否勾选
        this.form.remember = true
      }
      if (this.USER.getCookie() !== null && this.USER.getCookie() !== '0') {
        // 判断cookie中是否有保存账号
        this.form.username = this.USER.getCookie()
      }
      if (
        this.PASSWORD.getCookie() !== null && this.PASSWORD.getCookie() !== '0'
      ) {
        // 判断cookie中是否有保存密码
        const KEY = '1234567890123456'
        const IV = '1234567891012345'
        this.form.password = this.decrypt(this.PASSWORD.getCookie(), KEY, IV)
        // this.user.password = this.PASSWORD.getCookie()
        // this.md5Password = this.PASSWORD.getCookie()
      }
    },
    /**
     * @function 唤醒滑块验证码
     */
    openDrag() {
      if (this.Config.verificationSwitch) {
        this.$refs.drag.open()
      } else {
        this.loginSubmit()
      }
    },
    /**
     * @function 验证码关闭
     * @param {Number} status 1是成功，0是失败
     */
    closeDrag(status) {
      if (status) {
        this.loginSubmit()
      }
    },
    /**
     * @function 提交登录
     * @description 一般在滑块验证码通过后调用，因为滑块验证码还没好，先跳过验证码
     */
    loginSubmit() {
      const formData = { ...this.form }
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: false,
            text: '正在加载中.....',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
            customClass: "loading-login",
          });
          this.$http.post(apis.login, formData).then((res) => {
            loading.close();
            if (res.data.code === 0) {
              const data = { ...res.data }
              this.rememberPassword()
              this.Login(data)
              this.getRouter()
            }
          })
        }
      })
    },
    /**
     * @function 对登录的时候密码进行加密
     * @param {String} word 要被加密的文本
     * @param {String} keyStr 密钥
     * @param {String} ivStr  偏移量
     * @returns {String} 返回加密后的文本
     */
    encrypt(word, keyStr, ivStr) {
      const key = CryptoJS.enc.Utf8.parse(keyStr)
      const iv = CryptoJS.enc.Utf8.parse(ivStr)
      const srcs = CryptoJS.enc.Utf8.parse(word)
      const encrypted = CryptoJS.AES.encrypt(srcs, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
      return encrypted.toString()
    },
    /**
     * @function 对登录的时候密码进行解密
     * @param {String} word 要被解密的文本
     * @param {String} keyStr 密钥
     * @param {String} ivStr  偏移量
     * @returns {String} 返回解密后的文本
     */
    decrypt(word, keyStr, ivStr) { // 解密
      const key = CryptoJS.enc.Utf8.parse(keyStr)
      const iv = CryptoJS.enc.Utf8.parse(ivStr)
      const decrypt = CryptoJS.AES.decrypt(word, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      })
      const decryptedStr = CryptoJS.enc.Utf8.stringify(decrypt).toString()
      return decryptedStr
    },
    /**
     * @function 记住密码
     */
    rememberPassword() {
      if (this.form.remember) {
        this.USER.setCookie(this.form.username, 7) // cookie 保存账号
        const str = this.encrypt(this.form.password, '1234567890123456', '1234567891012345')
        this.PASSWORD.setCookie(str, 7) // cookie 保存密码
        // this.PASSWORD.setCookie((this.md5Password === this.user.password ? this.user.password : md5(this.user.password)), 7) // cookie 保存密码
        this.ISCHECKED.setCookie('1', 7) // cookie 保存勾选状态
      } else {
        this.form.username = ''
        this.form.password = ''
        this.USER.delCookie() // 删除 cookie
        this.PASSWORD.delCookie()
        this.ISCHECKED.delCookie()
      }
    },
    /**
     * @function 获取路由权限
     */
    getRouter() {
      this.$http.get(apis.permission_user).then((res) => {
        console.log(res)
        // debugger
        if (res.status === 200) {
          this.setRouter(res.data)
          console.log('router', res.data);
        }
        this.$router.push('/')
      })
    },
  },
  activated() {
    this.$refs.loginName.focus();
    // this.$http.get(apis.systemName).then((res) => {
    //   console.log(res);
    //   if (res.data.code === 0) {
    //     this.getSystemName(res.data.data.merchantSystemName);
    //     this.getSystemIcon(res.data.data.customIcon);
    //     document.title = res.data.data.merchantSystemName;
    //     const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    //     link.type = 'image/x-icon';
    //     link.rel = 'shortcut icon';
    //     link.href = res.data.data.customIcon;
    //     document.getElementsByTagName('head')[0].appendChild(link);
    //   }
    // })
  },
  mounted() {
    this.initLogin()
  },
  created() {
  },
}
</script>
<style lang="less" scoped>
@import url('../../../src/assets/styles/mixin.scoped.less');
.login-container{
    width: 100%;
    height: 100vh;
    background: url('../../../src/assets/images/login.jpg');
    background-size: cover;
    display: grid;
    justify-content: center;
    align-content: center;
    .login-wrap{
        width: 1684px;
        height: 926px;
        position: relative;
        background: url('../../../src/assets/images/login_wrap@2x.png');
        background-size: 100%;
        margin: 118px 160px;
        .title{
          font-size:34px;
          font-family:PingFangSC-Semibold,PingFang SC;
          font-weight:600;
          color:rgba(67,155,255,1);
          margin-top: 9px;
          margin-bottom: 20px;
        }
        .login-input-wrap{
            width: 364px;
            height: 100%;
            position: absolute;
            top: 0px;
						right: 116px;
						margin-top: 50px;
            .logo{
                width: 199px;
                margin-top: 150px;
            }
        }
    }
}
</style>
<style lang="less">
.input-noBorder-container .el-input__inner{
  border: none;
  border-bottom: 1px solid #94989E;
}
.loading-login {
  .el-icon-loading {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .el-loading-text {
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
